import { getGa, getUtmData } from '../../restocare/utils/ga';
import { sendDeliveryRequest } from '../../../../utils/api';

export const sendDeliveryFeedback = ({
  phone,
  name,
  comment,
  captcha,
  file,
  from,
}: Record<string, any>) => {
  const referrer = document?.referrer || '';
  const googleId = getGa();
  const utm = getUtmData();

  return sendDeliveryRequest({
    phone,
    name,
    file,
    comment,
    captcha,
    ...utm,
    googleId,
    referrer,
    from,
  });
};
