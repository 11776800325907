import React, { FC } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../i18n';

type PropsType = {
  isOpen: boolean;
  errorMessage?: string;
};

const OopsPanel: FC<PropsType> = ({ isOpen, errorMessage }) =>
  isOpen ? (
    <div className={styles.oopsPanel}>
      <span>{t(`errors.${errorMessage}`) || t('errors.oops_error')}</span>
    </div>
  ) : null;

export { OopsPanel };
