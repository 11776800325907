import { AttachInputValue } from '../components/inputs/AttachInput';

export const fileTypeValidator = (file: AttachInputValue) => {
  if (file.name.length > 0) {
    return !!file.name.match(/(\.jpg|\.jpeg|\.doc|\.docx|\.pdf)$/i);
  }

  return false;
};

export const fileValidatorAfterSubmit = (
  validator: any,
  file: AttachInputValue,
  setErrorFile: (arg0: string) => void
) => {
  if (!validator) {
    setErrorFile('');
  } else if (!fileTypeValidator(file) && file.name.length > 0) {
    setErrorFile(
      'Неподдерживаемый тип файлов (Доступно для загрузки: .jpg, .jpeg, .doc, .docx, .pdf)'
    );
  } else {
    setErrorFile('Заполните это поле');
  }
};
