/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useCallback } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../constants/hooks';
import { t } from '../../../i18n';

export interface AttachInputValue {
  name: string;
  content: string | ArrayBuffer | null;
}

interface IProps {
  value: { name: string; content: any };
  error?: string;
  label?: string;
  handler: React.Dispatch<React.SetStateAction<AttachInputValue>>;
}

const AttachInput: FC<IProps> = ({ value, handler, error, label }) => {
  const prefix = usePathPrefix();

  const onUpload = useCallback(
    (event) => {
      if (!event?.target?.files || !event.target.files.length) return;

      const { name } = event.target.files[0];

      const reader = new FileReader();

      // reader.readAsDataURL(event.target.files[0])
      reader.readAsArrayBuffer(event.target.files[0]);
      reader.onload = () => handler({ name, content: reader.result || '' });
    },
    [handler]
  );

  const onClear = useCallback(() => {
    handler({ name: '', content: '' });
  }, [handler]);

  return (
    <div>
      <div className={styles.attach}>
        <input type="file" onChange={onUpload} />

        <div className={styles.attach__icon}>
          <img src={`${prefix}/vacancy/attach.svg`} alt="" />
        </div>

        <div
          className={classnames(styles.attach__text, {
            [styles.filled]: value.name,
          })}
        >
          <div className={styles.attach__label}>
            {label ? t(label) : t('order.document')}
          </div>

          {value.name && (
            <div className={styles.attach__value}>{value.name}</div>
          )}
        </div>

        {!!value.content && (
          <div className={styles.clear} onClick={onClear}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.013 0.987C12.4679 0.441895 11.5841 0.441895 11.039 0.987L7 5.026L2.961 0.987001C2.4159 0.441896 1.53211 0.441895 0.987 0.987C0.441895 1.53211 0.441895 2.41589 0.987 2.961L5.026 7L0.987001 11.039C0.441896 11.5841 0.441895 12.4679 0.987 13.013C1.53211 13.5581 2.41589 13.5581 2.961 13.013L7 8.974L11.039 13.013C11.5841 13.5581 12.4679 13.5581 13.013 13.013C13.5581 12.4679 13.5581 11.5841 13.013 11.039L8.974 7L13.013 2.961C13.5581 2.4159 13.5581 1.5321 13.013 0.987Z"
                fill="#C0C0C0"
              />
            </svg>
          </div>
        )}
      </div>
      {error && <div className={styles.attach__error}>{t(error)}</div>}
    </div>
  );
};

export { AttachInput };
